//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBLG5ZP8lQold3mgNi98E5k2pPHmNSEif8",
  authDomain: "credefi-finance.firebaseapp.com",
  projectId: "credefi-finance",
  storageBucket: "credefi-finance.appspot.com",
  messagingSenderId: "611264650238",
  appId: "1:611264650238:web:e5352fae133c38d7a9beaf",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
