import React, { useEffect, useState, useRef } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/firestore'; // Adjust the path as needed
import { Link, NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { PiArrowRightBold, PiHandTap, PiTimerDuotone } from 'react-icons/pi';
import { IoCheckmarkCircleSharp, IoClose } from 'react-icons/io5';
import Animate from '../Components/Animate';
import Spinner from '../Components/Spinner';
import { RiArrowRightSLine } from 'react-icons/ri';
import { useUser } from '../context/userContext';
import Levels from '../Components/Levels';
import Exchanges from '../Components/Exchanges';
import { PiInfoFill } from 'react-icons/pi';
import { RiSettings4Fill } from 'react-icons/ri';
import { FaHeart } from 'react-icons/fa';
import SettingsMenu from '../Components/SettingsMenu';
import PphInfo from '../Components/PphInfo';
import GoldMinerGame from './GoldMiner';

const userLevels = [
  { id: 1, name: 'Bronze', icon: '/bronze.webp', tapBalanceRequired: 1000 },
  { id: 2, name: 'Silver', icon: '/silver.webp', tapBalanceRequired: 50000 },
  { id: 3, name: 'Gold', icon: '/gold.webp', tapBalanceRequired: 500000 },
  { id: 4, name: 'Platinum', icon: '/platinum.webp', tapBalanceRequired: 1000000 },
  { id: 5, name: 'Diamond', icon: '/diamond.webp', tapBalanceRequired: 2500000 },
  { id: 6, name: 'Master', icon: '/master.webp', tapBalanceRequired: 5000000 },
];

const slideUp = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-350px);
  }
`;

const SlideUpText = styled.div`
  position: absolute;
  animation: ${slideUp} 3s ease-out;
  font-size: 2.1em;
  color: #ffffffa6;
  font-weight: 600;
  left: ${({ x }) => x}px;
  top: ${({ y }) => y}px;
  pointer-events: none; /* To prevent any interaction */
`;

const Container = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
`;

const characters = [
  {
    name: 'boy',
    avatar: '/boy.webp',
  },
  {
    name: 'girl',
    avatar: '/girl.webp',
  },
];

const GoldHunters = () => {
  const {
    balance,
    tapBalance,
    energy,
    battery,
    profitHour,
    selectedCharacter,
    fullName,
    setFullName,
    characterMenu,
    setCharacterMenu,
    setSelectedCharacter,
    id,
    claimExchangePoint,
    setClaimExchangePoint,
    selectedExchange,
    setEnergy,
    setTapBalance,
    setBalance,
    refBonus,
    loading,
    initialized,
    isGameOpened,
    setIsGameOpened,
  } = useUser();

  const [points, setPoints] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [isTimerVisible, setIsTimerVisible] = useState(false);
  const [openClaim, setOpenClaim] = useState(false);
  const exchangeRef = useRef(null);
  const [congrats, setCongrats] = useState(false);
  const [glowBooster, setGlowBooster] = useState(false);
  const [showLevel, setShowLevel] = useState(false);
  const [showExchange, setShowExchange] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [exchangePoints, setExchangePoints] = useState(0);
  const [characterSelect, setCharacterSelect] = useState(true);
  const [fullNameSelect, setFullNameSelect] = useState(false);
  const [selectedCharacterOne, setSelectedCharacterOne] = useState({ name: '', avatar: '' });
  const [error, setError] = useState('');
  const [info, setInfo] = useState(false);

  const [gamesPlayedToday, setGamesPlayedToday] = useState(0); // Add gamesPlayedToday state

  const awardPoints = () => {
    const savedTime = localStorage.getItem('currentTime');
    if (savedTime) {
      const now = new Date();
      const savedDate = new Date(savedTime);

      const elapsedTime = (now - savedDate) / 1000; // Time difference in seconds
      const pointsToAward = elapsedTime * 0.8; // Points per second

      setExchangePoints((prevExchangePoints) => prevExchangePoints + pointsToAward);
    }
  };

  const claimExchangePoints = async (event) => {
    if (exchangeRef.current && !exchangeRef.current.contains(event.target)) {
      const now = new Date();
      localStorage.setItem('currentTime', now.toISOString());
      const exchangeUpdated = Math.floor(exchangePoints); // Convert to integer
      const newBalance = balance + exchangeUpdated;
      const userRef = doc(db, 'telegramUsers', id.toString());
      try {
        await updateDoc(userRef, {
          balance: newBalance,
          tapBalance: tapBalance + exchangeUpdated,
        });
        setBalance(newBalance);
        setTapBalance(tapBalance + exchangeUpdated);
        animateBalanceUpdate(newBalance); // Animate the balance update
        setClaimExchangePoint(false);
        setCongrats(true);

        setTimeout(() => {
          setCongrats(false);
        }, 4000);
      } catch (error) {
        console.error('Error updating balance exchanges', error);
      }
    }
  };

  const animateBalanceUpdate = (newBalance) => {
    const animationDuration = 300; // Animation duration in milliseconds
    const updateInterval = 20; // Update every 20 milliseconds
    const totalSteps = animationDuration / updateInterval;
    const increment = (newBalance - balance) / totalSteps; // Calculate increment per step
    let currentBalance = balance;
    let stepCount = 0;

    const intervalId = setInterval(() => {
      currentBalance += increment;
      stepCount += 1;
      if (stepCount >= totalSteps) {
        clearInterval(intervalId);
        currentBalance = newBalance;
      }
      setBalance(Math.floor(currentBalance.toFixed(0)));
    }, updateInterval);
  };

  const claimExchange = async () => {
    const now = new Date();
    localStorage.setItem('currentTime', now.toISOString());
    const exchangeUpdated = Math.floor(exchangePoints); // Convert to integer
    const newBalance = balance + exchangeUpdated;
    const userRef = doc(db, 'telegramUsers', id.toString());
    try {
      await updateDoc(userRef, {
        balance: newBalance,
        tapBalance: tapBalance + exchangeUpdated,
      });
      setBalance(newBalance);
      setTapBalance(tapBalance + exchangeUpdated);
      animateBalanceUpdate(newBalance); // Animate the balance update
      setClaimExchangePoint(false);
      setCongrats(true);

      setTimeout(() => {
        setCongrats(false);
      }, 4000);
    } catch (error) {
      console.error('Error updating balance exchanges', error);
    }
  };

  // Fetch gamesPlayedToday from Firestore
  useEffect(() => {
    const fetchGamesPlayedToday = async () => {
      if (id) {
        try {
          const userRef = doc(db, 'telegramUsers', id.toString());
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
            const userData = userSnap.data();
            setGamesPlayedToday(userData.gamesPlayedToday || 0); // Set gamesPlayedToday state
          } else {
            console.error('No user data found.');
          }
        } catch (error) {
          console.error('Error fetching gamesPlayedToday:', error);
        }
      }
    };

    fetchGamesPlayedToday();
  }, [id]);

  useEffect(() => {
    awardPoints();
    const savedTime = localStorage.getItem('currentTime');
    console.log('Current time saved:', savedTime);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      if (claimExchangePoint) {
        document.addEventListener('mousedown', claimExchangePoints);
      } else {
        document.removeEventListener('mousedown', claimExchangePoints);
      }

      return () => {
        document.removeEventListener('mousedown', claimExchangePoints);
      };
    }
    // eslint-disable-next-line
  }, [claimExchangePoint, id]);

  useEffect(() => {
    const savedEndTime = localStorage.getItem('endTime');
    if (savedEndTime) {
      const endTime = new Date(savedEndTime);
      const newTimeLeft = endTime - new Date();
      if (newTimeLeft > 0) {
        setIsDisabled(true);
        setIsTimerVisible(true);
        setTimeRemaining(newTimeLeft);
        const timer = setInterval(() => {
          const updatedTimeLeft = endTime - new Date();
          if (updatedTimeLeft <= 0) {
            clearInterval(timer);
            localStorage.removeItem('endTime');
            setIsDisabled(false);
            setIsTimerVisible(false);
            setEnergy(battery.energy);
          } else {
            setTimeRemaining(updatedTimeLeft);
          }
        }, 1000);
      } else {
        localStorage.removeItem('endTime');
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialized) {
      const savedEnergy = localStorage.getItem('energy');
    }
  }, [timeRemaining, energy, initialized]);

  const closeClaimer = () => {
    setOpenClaim(false);
    setPoints(0); // Reset points after claiming
  };

  const openClaimer = () => {
    setOpenClaim(true);
    setCongrats(true);

    setTimeout(() => {
      setCongrats(false);
    }, 4000);
  };

  const formatTimeRemaining = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const formatNumber = (num) => {
    if (num < 100000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, ' ');
    } else if (num < 1000000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, ' ');
    } else {
      return (num / 1000000).toFixed(3).replace('.', '.') + ' M';
    }
  };

  const initialLevelIndex = userLevels.findIndex((level) => tapBalance < level.tapBalanceRequired);
  const currentLevelIndex = initialLevelIndex === -1 ? userLevels.length - 1 : initialLevelIndex;
  const displayedLevelIndex = currentLevelIndex;
  const currentLevel = userLevels[displayedLevelIndex];

  const handleCharacterSelect = async (character) => {
    setSelectedCharacterOne(character);

    setCharacterSelect(false);
    setFullNameSelect(true);
  };

  const handleSaveFullName = async (newBalance) => {
    // Check if the fullName field is empty
    if (!fullName.trim()) {
      setError('Enter a name to proceed');
      return;
    }

    const saveCharacter = selectedCharacterOne;

    if (id) {
      try {
        // Update the Firestore document
        newBalance = balance + 100;
        const userRef = doc(db, 'telegramUsers', id.toString());
        await updateDoc(userRef, {
          fullName: fullName,
          character: {
            name: saveCharacter.name,
            avatar: saveCharacter.avatar,
          },
          balance: newBalance,
          tapBalance: newBalance,
        });

        setCharacterMenu(false);
        animateBalanceUpdate(newBalance); // Animate the balance update
        setBalance(newBalance);
        setTapBalance(newBalance);
        setSelectedCharacter({ name: saveCharacter.name, avatar: saveCharacter.avatar });
        setCongrats(true);

        setTimeout(() => {
          setCongrats(false);
        }, 4000);

        // Clear the error message if the update is successful
        setError('');
      } catch (error) {
        console.error('Error updating document:', error);
        setError('Failed to update user information. Please try again.');
      }
    }
   // console.log('Character Image is:', saveCharacter);
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Animate>
          <GoldMinerGame />
          <div
            className="w-full flex justify-center flex-col space-y-3"
            style={{ display: isGameOpened ? 'none' : 'block' }}
          >
            {/* <Spinner /> */}

            {/* KM START */}
            {/* Redesigned Home Screen Component with Enhanced 'Open Game' Button */}
            <div className="w-full h-screen flex flex-col px-5 py-4 bg-gradient-to-b from-gray-900 to-black text-white">
              {/* Top Section: User Info and Settings */}
              <div className="flex items-center justify-between mb-4">
                {/* User Avatar and Name */}
                <div className="flex items-center space-x-3">
                  <div className="relative w-12 h-12">
                    <img
                      src={selectedCharacter.avatar}
                      alt={fullName || 'User'}
                      className="w-full h-full rounded-full object-cover"
                    />
                    <span className="absolute bottom-0 right-0 w-4 h-4 bg-green-500 border-2 border-white rounded-full"></span>
                  </div>
                  <div>
                    <h1 className="text-lg font-bold">{fullName}</h1>
                    <p className="text-sm text-gray-400">CEO</p>
                  </div>
                </div>
                {/* Settings and Exchange Icons */}
                <div className="flex items-center space-x-4">
                  <button onClick={() => setShowExchange(true)}>
                    <img
                      id={selectedExchange.id}
                      src={selectedExchange.icon}
                      alt={selectedExchange.name}
                      className="w-8 h-8"
                    />
                  </button>
                  <button onClick={() => setShowSetting(true)}>
                    <RiSettings4Fill size={24} className="text-gray-300 hover:text-white" />
                  </button>
                </div>
              </div>

              {/* Middle Section: Profit and Level */}
              <div className="bg-gray-800 p-4 rounded-xl mb-4">
                {/* Profit per Hour */}
                <div className="flex items-center justify-between mb-2">
                  <div>
                    <p className="text-sm text-gray-400">Profit per Hour</p>
                    <h2 className="text-xl font-semibold text-green-400">+{formatNumber(profitHour)}</h2>
                  </div>
                  {/* Level Progress */}
                  <div className="w-1/2">
                    <div className="flex items-center justify-between mb-1">
                      <span className="text-sm font-medium">
                        Level {currentLevel.id}/{userLevels.length}
                      </span>
                      <button
                        onClick={() => setShowLevel(true)}
                        className="text-blue-500"
                        style={{
                          width: '53px',
                          display: 'flex',
                          flexWrap: 'nowrap',
                          alignItems: 'center',
                        }}
                      >
                        {currentLevel.name} <RiArrowRightSLine size={16} />
                      </button>
                    </div>
                    <div className="w-full bg-gray-700 rounded-full h-2">
                      <div
                        className="bg-blue-500 h-2 rounded-full"
                        style={{
                          width: `${(tapBalance / currentLevel.tapBalanceRequired) * 100}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                {/* Game Stats */}
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <img src="/loader.webp" alt="EngageCoin" className="w-8 h-8" />
                    <h2 className="text-2xl font-bold">
                      {formatNumber(balance + refBonus)} <span className="text-blue-500">pCREDI</span>
                    </h2>
                  </div>
                </div>
              </div>

              {/* Bottom Section: Claim and Boosters */}
              <div className="space-y-4">
                {/* Claim Rewards */}
                {energy === 0 && points === 0 ? (
                  <div
                    className={`${
                      glowBooster ? 'animate-pulse' : ''
                    } bg-gray-800 p-4 rounded-xl flex items-center justify-between`}
                  >
                    <>
                      <p className="text-gray-400">Need more taps? Get boosters now!</p>
                      <Link
                        to="/boost"
                        className="px-4 py-2 bg-blue-500 rounded-full text-black font-semibold hover:bg-blue-600"
                      >
                        Get Boosters
                      </Link>
                    </>
                  </div>
                ) : (
                  <></>
                )}
                {/* Taps Left and Boosters */}
                <div className="flex items-center justify-between">
                  {/* Taps Left */}
                  <div className="flex-1 bg-gray-800 p-4 rounded-xl mr-2 flex items-center justify-center">
                    {isTimerVisible ? (
                      <div className="flex items-center space-x-2">
                        <PiTimerDuotone size={20} className="text-blue-500" />
                        <span>{formatTimeRemaining(timeRemaining)}</span>
                      </div>
                    ) : (
                      <div className="flex items-center space-x-2">
                        <PiHandTap size={20} className="text-blue-500" />
                        <span>{gamesPlayedToday} games played today of 6</span> {/* Replaced taps left with games played */}
                      </div>
                    )}
                  </div>
                  {/* Boosters */}
                 
                </div>
              </div>

              {/* Enhanced Open Game Button */}
              <div className="flex-grow mt-4 relative">
                <div className="absolute inset-0 bg-black bg-opacity-50" aria-hidden="true">
                  <button
                    onClick={() => setIsGameOpened(true)}
                    className="relative z-10 w-full h-full bg-cover bg-center text-white font-bold text-2xl flex items-center justify-center"
                    style={{
                      backgroundImage: 'url(/cosmonaut.gif)',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      color: '#fff',
                      fontSize: '42px',
                      textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
                    }}
                  >
                    Start Game
                  </button>
                </div>
              </div>
            </div>

            {/* KM END */}

            {selectedExchange.id === 'selectex' || exchangePoints < 20 ? (
              <></>
            ) : (
              <div
                className={`${claimExchangePoint ? 'flex' : 'hidden'} fixed bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#303030c4] flex-col justify-end items-center`}
              >
                <div
                  ref={exchangeRef}
                  className={`w-full rounded-tl-[40px] rounded-tr-[40px] relative flex flex-col ease-in duration-300 transition-all justify-center`}
                >
                  <div className="w-full flex bg-[#202020] rounded-tl-[40px] rounded-tr-[40px] mt-[2px] justify-center relative flex-col items-center space-y-3 p-4 pt-20 pb-10">
                    <button
                      onClick={claimExchange}
                      className="flex items-center justify-center h-[32px] w-[32px] rounded-full bg-[#383838] absolute right-6 top-4 text-center font-medium text-[16px]"
                    >
                      <IoClose size={20} className="text-[#9995a4]" />
                    </button>

                    <div className="w-full bg-cards rounded-[16px] relative px-4 flex flex-col justify-center items-center">
                      <div className="w-[68px] h-[68px] -mt-[34px] rounded-full border-[2px] border-[#1F1F1F] bg-[#3b3b3b] items-center justify-center flex flex-col space-y-2">
                        <img src={selectedExchange.icon} alt={selectedExchange.name} className="w-[32px]" />
                      </div>
                      <div className="w-full items-center flex pt-1 justify-center space-x-[6px]">
                        <img src="/coin.webp" alt="coin" className="w-[36px]" />
                        <h3 className="font-bold text-[36px] text-[#ffffff] pt-2 pb-3 mt-[4px]">
                          <span className="text-accent">{formatNumber(exchangePoints.toFixed(0))}</span>
                        </h3>
                      </div>

                      <p className="pb-6 text-[#bfbfbf] font-semibold px-8 text-[17px] w-full text-center">
                        The exchange has started working for you
                      </p>
                    </div>
                    <div className="w-full flex justify-center pb-7">
                      <button
                        onClick={claimExchange}
                        className="bg-btn4 w-full py-[18px] px-6 text-nowrap flex items-center justify-center text-center rounded-[12px] font-semibold text-[17px]"
                      >
                        Thank you, {selectedExchange.name} <FaHeart size={17} className="mt-[2px] pl-1" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              className={`${
                characterMenu === true && selectedCharacter.name === '' ? 'visible' : 'invisible'
              } fixed bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#00000042] flex justify-center items-end backdrop-blur-[10px]`}
            >
              <div
                className={`w-full rounded-tl-[40px] rounded-tr-[40px] relative flex flex-col ease-in duration-300 transition-all justify-center`}
              >
                <div className="w-full flex bg-[#202020] rounded-tl-[40px] rounded-tr-[40px] mt-[2px] h-[85vh] justify-start relative flex-col items-center space-y-3 p-4 pb-24">
                  <div className="w-full flex flex-col text-center space-y-5 justify-center items-center py-8 relative">
                    <h1 className="font-semibold text-[18px]">Welcome!</h1>

                    <p className="text-[13px] px-6">Explore and earn your way up to riches and wealth!</p>

                    {characterSelect && (
                      <>
                        <h1 className="font-medium text-[14px] text-accent">Choose Character</h1>

                        <div className="w-full flex items-center justify-center space-x-5">
                          {characters.map((character, index) => (
                            <div key={index} className="w-[110px] h-[110px] relative flex items-center justify-center">
                              <div
                                onClick={() => handleCharacterSelect(character)}
                                className="w-[110px] h-[110px] bg-cards cursor-pointer hover:scale-[1.1] ease-in duration-300 rounded-full overflow-hidden relative items-center justify-center flex"
                              >
                                <img
                                  src={character.avatar}
                                  alt={character.name}
                                  className="object-cover absolute bottom-0 object-top"
                                />
                              </div>
                              <div class="w-[6px] absolute bottom-[-24px] h-[6px] bg-white rounded-full animate-pulse"></div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}

                    {fullNameSelect && (
                      <>
                        <h1 className="font-medium text-[14px] text-accent">Set your name to continue</h1>

                        <div className="w-full flex flex-col items-center space-y-3 px-6">
                          {error && <p style={{ color: 'red' }}>{error}</p>}
                          <input
                            type="text"
                            placeholder="Enter your full name"
                            className="w-full py-4 rounded-[8px] bg-cards flex justify-center text-center text-[16px] focus:outline-none outline-none border-none"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                          />
                          <div className="w-full px-6">
                            <button
                              className="px-6 w-full py-3 text-[15px] bg-accent text-[#000] font-semibold rounded-lg"
                              onClick={handleSaveFullName}
                            >
                              Contine to GoldCity
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Levels showLevel={showLevel} setShowLevel={setShowLevel} />
            <Exchanges showExchange={showExchange} setShowExchange={setShowExchange} />
            <SettingsMenu showSetting={showSetting} setShowSetting={setShowSetting} />
            <PphInfo info={info} setInfo={setInfo} />
          </div>
        </Animate>
      )}
    </>
  );
};

export default GoldHunters;
